import React, {useEffect, useState} from "react";
import "./styles.scss";
import {useWindowWidth} from "../../services/hooks";

const WinterSaleBanner = ({gridSection}) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 1024)
  }, [windowWidth])

  return (
    <div className={`winter-sale-banner ${gridSection ? "grid-section" : ""}`}>
      <div className="content-container">
        <div className="products-container">
          <div className="product">
            <span className="product-name">SkyHy by Hubble<sup>®</sup> Daily</span>
            <span className="price">$6.99/eye</span>
            <span className="period">for your first 1-month supply</span>
          </div>
          <div className="divider"></div>
          <div className="product">
            <span className="product-name">SkyHy by Hubble<sup>®</sup> Monthly</span>
            <span className="price">$2.99/eye</span>
            <span className="period">
              {isMobile ?
                "per month — for your first\n6-month supply" :
                "per month — for your first 6-month supply"
              }
            </span>
          </div>
        </div>

        <span className="sale-description">It’s the season for SkyHy. Get your first order of our most breathable lens for even less.</span>

        <span className="sale-time">
          {isMobile ?
            "limited time only\nnow through february 18" :
            "limited time only — now through february 18"
          }
        </span>
      </div>
    </div>
  )
}

export default WinterSaleBanner
